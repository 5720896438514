import "./main.css";
import {
    Elm
} from "./Main.elm";
import * as serviceWorker from "./serviceWorker";
import "../static/css/generated-tailwind.css";
import "../public/assets/style/main-style.css";
import SlideElement from "./slide_element";

// Static assets (e.g. images).
import bookshelfPath from "../public/images/bookshelf.png";
import prezanceLogoBlackPath from "../public/images/logo_text_black.png";
import blankProfilePath from "../public/images/blank-profile.png";
import tablePath from "../public/images/table.png";
import tableauPath from "../public/images/tableau.svg";
import textPath from "../public/images/text.svg";
import graphPath from "../public/images/graph.svg";
import paysagePath from "../public/images/paysage.svg";
import videoPath from "../public/images/video.svg";
import pdfPath from "../public/images/pdf.svg";
import magnetWhitePath from "../public/images/magnetic-zone/magnetwhite.png";
import magnetUpdateWhitePath from "../public/images/magnetic-zone/magnetupdatewhite.png";
import iconModeZa1Path from "../public/images/magnetic-zone/icon-mode-za1.png";
import h1IconPath from "../public/images/h1-icon.svg";
import h2IconPath from "../public/images/h2-icon.svg";
import magnetIconPath from "../public/images/magnet-icon.svg";
import magnetColorPath from "../public/images/magnetic-zone/aimant-2d.png";
import attributeIconPath from "../public/images/variable.svg";
import addIconPath from "../public/images/common-icons/ajout.svg";
import videoLinkPath from "../public/images/videolink.svg";
import framePath from "../public/images/frame.svg";
import lorem3Path from "../public/images/lorem3.svg";
import lorem1Path from "../public/images/lorem1.svg";
import lorem2Path from "../public/images/lorem2.svg";
import fakeAlignPath from "../public/images/fake-align.svg";
import ticketPath from "../public/images/ticket.svg";
import basrosePath from "../public/images/bas-rose.svg";
import basnoirPath from "../public/images/bas-noir.svg";
import barPath from "../public/images/bar.svg";
import settingsPath from "../public/images/settings.svg";
import positionPath from "../public/images/position.svg"
import sonnettePath from "../public/images/sonnette.svg";
import ellipsePath from "../public/images/ellipse.svg"
import projectPath from "../public/images/icon-project.svg";
import stepPath from "../public/images/step.svg";


const prezanceUserKey = "prezance_user";

const storedUser = localStorage.getItem(prezanceUserKey);

SlideElement.register();


// -------------------------------------------------------------------------------------------------
// https://discourse.elm-lang.org/t/custom-elements-extend-svg-real-coordinates-on-mouse-events/1762
// Their solution did not work if the SVG was inside a div
// So I just query the SVG by class name "svg-events"
//
// We need a "svg-events" class on all the thing we need svg coordinates
// -------------------------------------------------------------------------------------------------
function onMouseUp(event) {
    var point = this.createSVGPoint()
    point.x = event.clientX
    point.y = event.clientY
    var position = point.matrixTransform(this.getScreenCTM().inverse())
    var svgClickEvent = new CustomEvent('svgMouseUp', {
        detail: {
            x: position.x,
            y: position.y,
            clientX: event.clientX,
            clientY: event.clientY
        }
    });
    event.preventDefault();
    event.currentTarget.dispatchEvent(svgClickEvent);
}

function onMouseDown(event) {
    var point = this.createSVGPoint()
    point.x = event.clientX
    point.y = event.clientY
    var position = point.matrixTransform(this.getScreenCTM().inverse())
    var svgClickEvent = new CustomEvent('svgMouseDown', {
        detail: {
            x: position.x,
            y: position.y,
            clientX: event.clientX,
            clientY: event.clientY
        }
    });
    event.currentTarget.dispatchEvent(svgClickEvent);
}

function onMouseMove(event) {
    var point = this.createSVGPoint()
    point.x = event.clientX
    point.y = event.clientY
    var position = point.matrixTransform(this.getScreenCTM().inverse())
    var svgClickEvent = new CustomEvent('svgMouseMove', {
        detail: {
            x: position.x,
            y: position.y,
            clientX: event.clientX,
            clientY: event.clientY
        }
    });
    event.currentTarget.dispatchEvent(svgClickEvent);
}

function onContextMenu(event) {
    var point = this.createSVGPoint()
    point.x = event.clientX
    point.y = event.clientY
    var position = point.matrixTransform(this.getScreenCTM().inverse())
    var svgClickEvent = new CustomEvent('svgContextMenu', {
        detail: {
            x: position.x,
            y: position.y,
            clientX: event.clientX,
            clientY: event.clientY
        }
    });
    event.currentTarget.dispatchEvent(svgClickEvent);
    event.preventDefault()
}

var SVG_EVENTS_CLASS_NAME = "svg-events"
var observer = new MutationObserver(function (mutations) {
    // look through all mutations that just occured
    for (var i = 0; i < mutations.length; ++i) {
        // look through all added nodes of this mutation
        for (var j = 0; j < mutations[i].addedNodes.length; ++j) {
            var addedNode = mutations[i].addedNodes[j]

            // the node itself can be a svg-events
            if (addedNode.classList && addedNode.classList.contains(SVG_EVENTS_CLASS_NAME)) {
                //console.log("adding events listeners to SVG element:", addedNode)

                addedNode.removeEventListener('contextmenu', onContextMenu)
                addedNode.removeEventListener('mouseup', onMouseUp)
                addedNode.removeEventListener('mousedown', onMouseDown)
                addedNode.removeEventListener('mousemove', onMouseMove)
                addedNode.addEventListener('contextmenu', onContextMenu)
                addedNode.addEventListener('mouseup', onMouseUp)
                addedNode.addEventListener('mousedown', onMouseDown)
                addedNode.addEventListener('mousemove', onMouseMove)
            }

            // and maybe some of its children
            if (addedNode.getElementsByClassName) {
                Array.from(addedNode.getElementsByClassName(SVG_EVENTS_CLASS_NAME))
                    .forEach(function (node) {
                        //console.log("adding events listeners to SVG element:", node)

                        node.removeEventListener('contextmenu', onContextMenu)
                        node.removeEventListener('mouseup', onMouseUp)
                        node.removeEventListener('mousedown', onMouseDown)
                        node.removeEventListener('mousemove', onMouseMove)
                        node.addEventListener('contextmenu', onContextMenu)
                        node.addEventListener('mouseup', onMouseUp)
                        node.addEventListener('mousedown', onMouseDown)
                        node.addEventListener('mousemove', onMouseMove)
                    });
            }

            // sometimes the mutation is not on the SVG but on the G
            // example when adding a variant in viewer
            if (addedNode.tagName == "g" && addedNode.parentElement.classList.contains(SVG_EVENTS_CLASS_NAME)) {

                //console.log("adding events listeners to SVG element:", addedNode.parentElement)

                addedNode.parentElement.removeEventListener('contextmenu', onContextMenu)
                addedNode.parentElement.removeEventListener('mouseup', onMouseUp)
                addedNode.parentElement.removeEventListener('mousedown', onMouseDown)
                addedNode.parentElement.removeEventListener('mousemove', onMouseMove)
                addedNode.parentElement.addEventListener('contextmenu', onContextMenu)
                addedNode.parentElement.addEventListener('mouseup', onMouseUp)
                addedNode.parentElement.addEventListener('mousedown', onMouseDown)
                addedNode.parentElement.addEventListener('mousemove', onMouseMove)
            }
        }
    }
});
observer.observe(document.body, {
    childList: true,
    subtree: true
});
// -------------------------------------------------------------------------------------------------

const app = Elm.Main.init({
    node: document.getElementById("root"),
    flags: {
        user: storedUser,
        locale: navigator.language || navigator.userLanguage,
        assets: {
            bookshelfPath: bookshelfPath,
            prezanceLogoBlackPath: prezanceLogoBlackPath,
            blankProfilePath: blankProfilePath,
            tablePath: tablePath,
            graphPath: graphPath,
            videoPath: videoPath,
            pdfPath: pdfPath,
            videoLinkPath: videoLinkPath,
            magnetWhitePath: magnetWhitePath,
            magnetUpdateWhitePath: magnetUpdateWhitePath,
            iconModeZa1Path: iconModeZa1Path,
            h1IconPath: h1IconPath,
            h2IconPath: h2IconPath,
            textPath: textPath,
            paysagePath: paysagePath,
            tableauPath: tableauPath,
            magnetIconPath: magnetIconPath,
            magnetColorPath: magnetColorPath,
            addIconPath: addIconPath,
            attributeIconPath: attributeIconPath,
            projectPath: projectPath,
            framePath: framePath,
            lorem3Path: lorem3Path,
            lorem1Path: lorem1Path,
            lorem2Path: lorem2Path,
            fakeAlignPath: fakeAlignPath,
            ticketPath: ticketPath,
            positionPath: positionPath,
            sonnettePath: sonnettePath,
            barPath: barPath,
            basrosePath: basrosePath,
            settingsPath: settingsPath,
            basnoirPath: basnoirPath,
            basrosePath: basrosePath,
            ellipsePath: ellipsePath,
            stepPath: stepPath
        },
    },
});

app.ports.getDim.subscribe(function (url) {
    let img = new Image()
    img.src = url
    img.onload = function () {
        app.ports.newDim.send([img.width, img.height])
    }
});

app.ports.storeUser.subscribe((user) => {
    localStorage.setItem(prezanceUserKey, user);
});

app.ports.logout.subscribe((_) => {
    localStorage.removeItem(prezanceUserKey);
});

window.addEventListener('storage', function (event) {
    if (event.key === prezanceUserKey && event.newValue === null) {
        app.ports.userLoggedOutOnAnotherTab.send(null);
    }
}, false);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();




/**
 * QUILL PORTS
 **/
let focusedQuill;

// load fonts
let Font = Quill.import('formats/font');
Font.whitelist = ['serif', "monospace"];
Quill.register(Font, true);

// load sizes
let Size = Quill.import('attributors/style/size');
Size.whitelist = ["8px", "9px", "10px", "11px", "12px", "13px", "14px", "16px", "18px", "20px", "24px", "36px", "48px", "64px", "72px", "96px", "128px", "150px"]
Quill.register(Size, true);

// load aligns
let Aligns = Quill.import('formats/align');
Aligns.whitelist = ['right', 'left', 'center', 'justify'];
Quill.register(Aligns, true);

let Headers = Quill.import('formats/header');
Headers.whitelist = [1, 2, 3, 4];
Quill.register(Headers, true);


app.ports.createQuillEditor.subscribe(([textBoxId, initialContents]) => {
    // because UUID might start with number, we can't use #My-id format
    const selector = "[id='" + textBoxId + "']"
    if (document.querySelector(selector) == null) {
        console.log("Skipping creation of Quill for ", textBoxId, " because it's not on DOM")
        return;
    }
    console.log('Creating Quill for', textBoxId)

    // We stop propagation of the mouseup otherwhise the SlidesEditor will catch it
    // and will mostly exit the text-edition (unwanted behaviour when user select text)
    document.querySelector(selector).addEventListener("mouseup", function (e) {
        e.stopPropagation();
    })

    let quill = new Quill(selector, {
        placeholder: "Insert text here"
    });
    focusedQuill = quill

    // We can't use ELM to fill-in the initial text because otherwise it'll
    // try to update the component on each modification which break everything
    if (initialContents) {
        quill.setContents(JSON.parse(initialContents))
    }

    quill.on("text-change", function (delta, oldDelta, source) {
        // We use HTML for read-only display
        // We use Quill for writable display
        if (quill.getText().trim().length == 0) {
            app.ports.onQuillTextChange.send([textBoxId, "", ""]);

        } else {
            const contentsHTML = quill.container.firstChild.innerHTML;
            const contentsQUILL = JSON.stringify(quill.getContents());
            app.ports.onQuillTextChange.send([textBoxId, contentsHTML, contentsQUILL]);
        }
    });

    // We need to keep track on which editor is focused to be able to use
    // the buttons on it.
    // We could also track it in ELM with a subscriptions but that'll be harder for the same goal
    quill.on("selection-change", function (range, oldRange, source) {
        if (range && !oldRange && source == "user") {
            focusedQuill = quill
            var contents = JSON.stringify(quill.getContents());
            app.ports.onQuillSelect.send([textBoxId, contents]);
        } else if (!range) {
            // This cause a bug when clicking outside of the editor, we cannot come back in
            // focusedQuill = null
            // quill.enable(false)
            // quill = null
        }

    });

    // focus the editor
    quill.focus()
    // caret at end
    quill.setSelection(quill.getLength(), 0);
});

app.ports.quillFormatBold.subscribe(() => formatToggleQuill("bold"));
app.ports.quillFormatItalic.subscribe(() => formatToggleQuill("italic"));
app.ports.quillFormatStrike.subscribe(() => formatToggleQuill("strike"));
app.ports.quillFormatUnderline.subscribe(() => formatToggleQuill("underline"));
app.ports.quillFormatColor.subscribe((colorStr) => formatQuill("color", colorStr));
app.ports.quillFormatBackgroundColor.subscribe((colorStr) => formatQuill("background", colorStr));
app.ports.quillFormatFont.subscribe((font) => formatQuill("font", font));
app.ports.quillFormatSize.subscribe((size) => formatQuill("size", size));
app.ports.quillFormatAlign.subscribe((align) => formatQuill("align", align));
app.ports.quillFormatOList.subscribe(() => formatToggleQuill2("list", "ordered"));
app.ports.quillFormatUList.subscribe(() => formatToggleQuill2("list", "bullet"));
app.ports.quillFormatHeader.subscribe((header) => {
    // Cancel a few other props
    formatQuill("size", false);
    formatQuill("font", false);
    formatQuill("bold", false);
    formatQuill("italic", false);
    formatQuill("strike", false);
    formatQuill("color", false);
    formatQuill("background", false);
    formatQuill("underline", false);

    // Without the timeout it will fail to apply correctly
    setTimeout(() => formatToggleQuill2("header", +header), 50)
});

// Toggle a boolean format (bold/underline/italic etc.)
function formatToggleQuill(key) {
    if (!focusedQuill) return;

    const currentFormat = focusedQuill.getFormat();
    if (currentFormat.hasOwnProperty(key)) {
        focusedQuill.format(key, !currentFormat[key])
    } else {
        focusedQuill.format(key, true)
    }
}

// Toggle a format (list/header)
function formatToggleQuill2(key, value) {
    if (!focusedQuill) return;

    const currentFormat = focusedQuill.getFormat();
    if (currentFormat.hasOwnProperty(key)) {
        if (currentFormat[key] == value) {
            focusedQuill.format(key, false)
        } else {
            focusedQuill.format(key, value)
        }
    } else {
        focusedQuill.format(key, value)
    }
}

// Set a format
function formatQuill(key, value) {
    if (!focusedQuill) return;
    focusedQuill.format(key, value)
}



/**
 * X-SPREADSHEET PORTS
 **/

const DEFAULT_CELL = {
    height: 25
    , width: 100
    , fontFamily: "Arial"
    , fontSize: 10
    , foregroundColor: "black"
    , bold: false
    , italic: false
    , strikethrough: false
    , underline: false
    , backgroundColor: "white"
    , hAlign: "left"
    , vAlign: "top"
    , value: ""
}

let editorWindow;

app.ports.openXSpreadsheetEditor.subscribe(([divId, sheet]) => {
    const w = 950
    const h = 400
    const x = screen.width / 2 - w / 2
    const y = screen.height / 2 - h / 2

	editorWindow = window.open('', '', 'width=' + w + ',height=' + h + ',left=' + x + ',top=' + y)
	if (!editorWindow) {
	    console.log("popup blocked")
	    return
	}

    let editorDocument = editorWindow.document
    editorDocument.title = 'Table Edition - Prézance'

    // Write the div
    var editorDiv = editorDocument.createElement( "div" );
    editorDiv.id = divId
    editorDiv.setAttribute("style","width: 100%; height: 100%");
    editorDocument.body.appendChild(editorDiv);

    // Write the CSS & Javascript
    var link = editorDocument.createElement( "link" );
    link.href = location.origin + "/xspreadsheet.css"
    link.type = "text/css";
    link.rel = "stylesheet";
    link.media = "screen,print";

    var script = editorDocument.createElement('script');
    script.onload = function () {
       const selector = "[id='" + divId + "']"
       const divEl = editorDocument.querySelector(selector)
       if (!divEl) {
           console.log("Skipping creation of X-Spreadsheet for ", divId, " because it's not on DOM")
           return;
       }
       console.log('Creating X-Spreadsheet for', divId)
       let s = editorWindow.x_spreadsheet(selector, {
           mode: 'edit', // edit | read
           showToolbar: true,
           showGrid: true,
           showContextmenu: true,
           showBottomBar: false,
           view: {
               height: () => divEl.offsetHeight,
               width: () => divEl.offsetWidth,
           }
       })
       // equivalent to init
       if (sheet !== null) {
            s.loadData([prz_to_x_spreadsheet(sheet)])
       }

       // equivalent to onInput
       s.change(data => {
            console.log("change", data)
           app.ports.onSheetChange.send(x_spreadsheet_to_prz(data))
       })

       // Send an event to elm to know it was closed
       editorWindow.addEventListener('unload', function() {
           app.ports.onSheetEditorClosed.send(x_spreadsheet_to_prz(s.getData()[0]))
       })
    };
    script.src = location.origin + "/xspreadsheet.js";

    editorDocument.getElementsByTagName( "head" )[0].appendChild( link );
    editorDocument.getElementsByTagName( "head" )[0].appendChild( script );


});

app.ports.closeXSpreadsheetEditor.subscribe(() => {
    if (editorWindow) {
        editorWindow.close()
    }
})


function prz_to_x_spreadsheet(sheet) {
    const MINIMUM_NUMBER_OF_ROWS = 1
    const MINIMUM_NUMBER_OF_COLS = 1

    // determine number of columns
    let colsLength = MINIMUM_NUMBER_OF_COLS
    if (sheet.cells.length > 0)
        colsLength = Math.max(MINIMUM_NUMBER_OF_COLS, sheet.cells[0].length)

    let styles = []
    let cols = { len: colsLength }
    let rows = { len: Math.max(MINIMUM_NUMBER_OF_ROWS, sheet.cells.length) }

    // loop the rows
    for (let i = 0; i < sheet.cells.length; i++) {
        let cells = {}
        // loop the cols
        for (let j = 0; j < sheet.cells[i].length; j++) {
            const cell = sheet.cells[i][j]
            cols[j] = {width: cell.width}
            cells[j] = { text: cell.value, style: styles.length }
            styles.push({
                //            border: {
                //                top: ['thin', '#0366d6'],
                //                bottom: ['thin', '#0366d6'],
                //                right: ['thin', '#0366d6'],
                //                left: ['thin', '#0366d6'],
                //            },
                color: cell.foregroundColor,
                bgcolor: cell.backgroundColor,
                strike: cell.strikethrough,
                underline: cell.underline,
                font: {
                    name: cell.fontFamily,
                    size: cell.fontSize,
                    bold: cell.bold,
                    italic: cell.italic
                },
                align: cell.hAlign.toLowerCase(),
                valign: cell.vAlign.toLowerCase()
            })
        }
        rows[i] = { cells, height: sheet.cells[i][0].height }
    }

    return {
        name: sheet.spreadsheetName,
        cols,
        rows,
        styles
    }
}


function x_spreadsheet_to_prz(data) {
    const rowsCount = 1 + Math.max(...Object.keys(data.rows)
        .map((key) => +key)
        .filter((n) => !isNaN(n))
    )
    const colsCount = 1 + Math.max(...Object.keys(data.rows)
        .map((key) => {
            let cols = data.rows[key].cells
            if (!cols) return []

            return Object.keys(cols)
        })
        .flat()
    )


    let cells = []
    for (let i = 0; i < rowsCount; i++) {
        const row = data.rows[i]
        if (!row) {
            // creates an empty line
            cells.push(Array(colsCount).fill(DEFAULT_CELL))
        } else {
            const height = row.height || 25
            let cols = []
            for (let j = 0; j < colsCount; j++) {
                const cell = row.cells[j]
                const width = data.cols[j] && data.cols[j].width ? data.cols[j].width : 100

                if (!cell) {
                    // creats an empty cell
                    cols.push({ ...DEFAULT_CELL, height: height })
                } else {
                    if (cell.hasOwnProperty("style")) {
                        // creates a cell with style
                        // style only contains the non-default, so we have to fallback
                        const style = data.styles[cell.style]
                        cols.push({
                            height: height
                            , width: width
                            , fontFamily: style.font && style.font.name ? style.font.name : DEFAULT_CELL.fontFamily
                            , fontSize: style.font && style.font.size ? style.font.size : DEFAULT_CELL.fontSize
                            , foregroundColor: style.color || DEFAULT_CELL.foregroundColor
                            , bold: style.font && style.font.bold ? style.font.bold : DEFAULT_CELL.bold
                            , italic: style.font && style.font.italic ? style.font.italic : DEFAULT_CELL.italic
                            , strikethrough: style.strike || DEFAULT_CELL.strikethrough
                            , underline: style.underline || DEFAULT_CELL.underline
                            , backgroundColor: style.bgcolor || DEFAULT_CELL.backgroundColor
                            , hAlign: style.align || DEFAULT_CELL.hAlign
                            , vAlign: style.valign || DEFAULT_CELL.vAlign
                            , value: cell.text || ""
                        })
                    } else {
                        // creates a cell with no style
                        cols.push({ ...DEFAULT_CELL, height: height, width: width, value: cell.text || "" })
                    }

                }
            }
            cells.push(cols)
        }
    }

    const svg = cells_to_svg(cells)
    return {
        spreadsheetName: data.name,
        cells: cells,
        encodedRangeImage: btoa(svg)
    }
}

// 100% copy of Google Sheet Addin "Sheet.build_svg()" function
function cells_to_svg(cells) {
  let maxTotalHeight = 0;
  let maxTotalWidth = 0;
  let trs = '';
  for (var i = 0; i < cells.length; i++) {
    let rowTotalWidth = 0;
    let rowHeight = 0;
    let tds = '';
    for (var j = 0; j < cells[i].length; j++) {
      rowHeight = cells[i][j].height;
      rowTotalWidth += cells[i][j].width;
      let cellStyle = [];
      cellStyle.push(`width: ${cells[i][j].width}px`);
      cellStyle.push(`height: ${cells[i][j].height}px`);
      cellStyle.push(`background-color: ${cells[i][j].backgroundColor}`);
      cellStyle.push(`font-family: ${cells[i][j].fontFamily}`);
      cellStyle.push(`color: ${cells[i][j].foregroundColor}`);
      cellStyle.push(`font-size: ${cells[i][j].fontSize}pt`);
      cellStyle.push(`font-weight: ${cells[i][j].bold ? 'bold' : 'normal'}`);
      cellStyle.push(`font-style: ${cells[i][j].italic ? 'italic' : 'normal'}`);

      // Underline or Strikethrough
      if (cells[i][j].strikethrough) {
        cellStyle.push(`text-decoration: line-through`);
      } else if (cells[i][j].underline) {
        cellStyle.push(`text-decoration: underline`);
      } else {
        cellStyle.push(`text-decoration: normal`);
      }

      // Align Horizontal
      switch (cells[i][j].hAlign) {
        case 'general-left':
          cellStyle.push(`text-align: left`);
          break;

        case 'center':
          cellStyle.push(`text-align: center`);
          break;

        case 'right':
          cellStyle.push(`text-align: right`);
          break;
      }

      // Align Vertical
      switch (cells[i][j].vAlign) {
        case 'top':
          cellStyle.push(`vertical-align: top`);
          break;

        case 'middle':
          cellStyle.push(`vertical-align: middle`);
          break;

        case 'bottom':
          cellStyle.push(`vertical-align: bottom`);
          break;
      }

      tds += `
          <xhtml:td class="td" style="${cellStyle.join(';')}">
            ${cells[i][j].value}
          </xhtml:td>
         `;
    }
    maxTotalWidth = Math.max(maxTotalHeight, rowTotalWidth);
    maxTotalHeight += rowHeight;
    trs += `
        <xhtml:tr class="tr">${tds}</xhtml:tr>
      `;
  }

  maxTotalHeight += cells.length * 4 * 2; // 4px padding *2 per row
  maxTotalHeight += cells.length + 1; // 1px per row + 1 (border)

  return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xhtml="http://www.w3.org/1999/xhtml" version="1.1" viewBox="0 0 ${maxTotalWidth} ${maxTotalHeight}">
    <style>
      .td { padding: 4px; display: table-cell; border: 1px solid black }
      .tr { display: table-row }
      .table { display: table; border-collapse: collapse }
    </style>
    <foreignObject x="0" y="0" width="${maxTotalWidth}" height="${maxTotalHeight}">
      <xhtml:table class="table">
        ${trs}
      </xhtml:table>
    </foreignObject>
    </svg>`;
}