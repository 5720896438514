function register() {
    customElements.define("slide-custom",
        class extends HTMLElement {
            constructor() {
                super();
            }
            connectedCallback() {
                this.mousemoveHandler = inEvt => {
                    const bounds = this.getBoundingClientRect();
                    let outEvt = new Event("mousemove-custom");
                    outEvt.x = inEvt.clientX - bounds.left;
                    outEvt.y = inEvt.clientY - bounds.top;
                    this.dispatchEvent(outEvt);

                };
                this.addEventListener("mousemove", this.mousemoveHandler);
                this.mouseclick = inEvt => {
                    const bounds = this.getBoundingClientRect();
                    let outEvt = new Event("mouseup-custom");
                    outEvt.x = inEvt.clientX - bounds.left;
                    outEvt.y = inEvt.clientY - bounds.top;
                    outEvt.button = inEvt.button;
                    this.dispatchEvent(outEvt);
                };
                this.addEventListener("mouseup", this.mouseclick);
            }

            disconnectedCallback() {
                this.removeEventListener("mousemove", this.mousemoveHandler);
                this.removeEventListener("mouseup", this.mouseclick);

            }

            attributeChangedCallback(name, oldValue, newValue) {

            }

            // static get observedAttributes() { return []; }

        }
    );
}

export default {
    register: register
}
